import { DashboardStatistics } from 'components/DashboardStatistics';
import { DateRangePicker } from 'components/DateRangePicker';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { RichText } from 'components/RichText';
import { OccupationChart } from 'components/SnapshotsChart';
import { VerticalMargin } from 'components/VerticalMargin';
import { useBuildings, useSnapshots, useStatistics } from 'helpers/api';
import type { NextPage } from 'next';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.css';

const DashboardPage: NextPage = () => (
  <Page title="Dashboard">
    <PageContent />
  </Page>
);

// PageContent is in a seperate component so Page can determine if it is loading or not and these hooks don't fire yet
const PageContent = () => {
  const [graphDataStartDate, setGraphDataStartDate] = useState<Date | null>(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)
  );
  const [graphDataEndDate, setGraphDataEndDate] = useState<Date | null>(new Date());
  const [totalCostsAllBuildings, setTotalCostsAllBuildings] = useState(0);
  const [totalIncomeAllBuildings, setTotalIncomeAllBuildings] = useState(0);
  const [totalProfitAllBuildings, setTotalProfitAllBuildings] = useState(0);

  const { data, isLoading, isRefetching } = useStatistics();
  const { data: buildings } = useBuildings();

  const {
    data: snapshots,
    refetch,
    isLoading: isLoadingSnapshots,
    isRefetching: isRefetchingSnapshots,
  } = useSnapshots([new Date(graphDataStartDate || '').toISOString(), new Date(graphDataEndDate || '').toISOString()]);

  const onChangeStartDate = useCallback((date: Date | null) => {
    setGraphDataStartDate(date);
  }, []);
  const onChangeEndDate = useCallback((date: Date | null) => {
    setGraphDataEndDate(date);
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch, graphDataStartDate, graphDataEndDate]);

  useEffect(() => {
    if (!buildings || totalCostsAllBuildings !== 0 || totalIncomeAllBuildings !== 0 || totalProfitAllBuildings !== 0)
      return;

    const filteredBuildings = buildings.filter(
      (building) => building.status !== 'inactive' && building.status !== 'archived'
    );
    for (const building of filteredBuildings) {
      setTotalCostsAllBuildings((previousValue) => previousValue + building.monthlyCost);
      setTotalIncomeAllBuildings((previousValue) => previousValue + building.totalMonthlyIncome);
      setTotalProfitAllBuildings((previousValue) => previousValue + building.monthlyProfit);
    }
  }, [buildings, totalCostsAllBuildings, totalIncomeAllBuildings, totalProfitAllBuildings]);

  return (
    <React.Fragment>
      <PageHeader showLoader={isLoading || isRefetching} title="Dashboard" />
      {!!data && (
        <VerticalMargin>
          <DashboardStatistics
            totalCostsAllBuildings={totalCostsAllBuildings}
            totalIncomeAllBuildings={totalIncomeAllBuildings}
            data={data}
            totalProfitAllBuildings={totalProfitAllBuildings}
          />
        </VerticalMargin>
      )}
      <div className={styles.titleContainer}>
        <RichText>
          <h2>Bezettingshistorie (inclusief reserveringen)</h2>
        </RichText>
        <DateRangePicker
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          startDate={graphDataStartDate}
          endDate={graphDataEndDate}
        />
      </div>
      <OccupationChart snapshots={snapshots} isLoading={isLoadingSnapshots || isRefetchingSnapshots} />
    </React.Fragment>
  );
};

export default DashboardPage;
